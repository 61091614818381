<template>
  <div class="blog">
    <div class="blog__header container">
      <div class="blog__header__body_left">
        <h3 class="section-title-sm">
          {{ $vuetify.lang.t("$vuetify.view.blog.header") }}
        </h3>
        <p class="section-subtitle-sm mt-8">
          {{ $vuetify.lang.t("$vuetify.view.blog.subtitle1") }}
          <br />
          {{ $vuetify.lang.t("$vuetify.view.blog.subtitle2") }}
        </p>
        <div class="body__additionally">
          <span class="body__additionally_text">
            {{ $vuetify.lang.t("$vuetify.view.blog.andMore") }}
          </span>
          <img
            class="body__additionally_arrow"
            src="@/assets/images/blog/header__arrow.png"
            alt="arrow_down"
          />
        </div>
      </div>
      <div class="blog__header__body_right">
        <img src="@/assets/images/blog/header_right.jpg" alt="people" />
      </div>
    </div>
    <div class="devider"></div>
    <v-container fluid>
      <div class="blog__action">
        <span>Блог</span>
        <div class="blog__categories_wrapper">
          <v-select
            v-if="localisation === `uk`"
            class="blog__categories_item"
            v-model="categoriesValue"
            :items="categoriesItems"
            item-value="id"
            item-text="name.uk"
            label="Категорії"
            small-chips
            outlined
            dense
            rounded
            hide-details
            multiple
          ></v-select>
          <v-select
            v-if="localisation === `ru`"
            class="blog__categories_item"
            v-model="categoriesValue"
            :items="categoriesItems"
            item-value="id"
            item-text="name.ru"
            label="Категории"
            small-chips
            outlined
            dense
            rounded
            hide-details
            multiple
          ></v-select>
        </div>
        <div class="blog__search_wrapper">
          <input
            v-model="searchInput"
            v-debounce:750="debounceSearchInputByTitleOrCategories"
            class="blog__search_input input-search"
            :placeholder="$vuetify.lang.t(`$vuetify.view.blog.search`)"
            type="search"
          />
          <v-icon
            v-if="!handleSearchIconOrCircular"
            size="25"
            class="blog__search_icon"
          >mdi-magnify</v-icon
          >
          <v-progress-circular
            class="blog__search_icon"
            v-if="handleSearchIconOrCircular"
            size="25"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </div>
      <div class="blog__container">
        <div
          v-for="(article, index) in blogArticles.data"
          :key="article.slug + '_' + index"
          class="blog__container__item"
        >
          <img :src="article.preview_image" alt="preview_image" />
          <div class="blog__container__item__body">
            <router-link
              class="blog__container__item__body_title"
              :to="{
                name: 'blog-post',
                params: {
                  slug: article.card_title_slug,
                  page: page,
                  categories: categoriesQueryString,
                  title: searchInput,
                },
              }"
            >{{ currentLocal(article.title) }}</router-link
            >
            <p class="blog__container__item__body_subtitle">
              {{ currentLocal(article.short_description) }}
            </p>
          </div>
          <v-btn
            :to="{
                name: 'blog-post',
                params: {
                  slug: article.card_title_slug,
                  page: page,
                  categories: categoriesQueryString,
                  title: searchInput,
                },
              }"
            plain
            color="primary"
            small
            class="blog__container__item__btn"
          >
            {{ $vuetify.lang.t("$vuetify.view.blog.readMore") }}
          </v-btn>
        </div>
      </div>
      <v-pagination
        class="mt-6"
        v-model="page"
        :length="blogArticles.meta.last_page"
        :total-visible="6"
      ></v-pagination>
    </v-container>
  </div>
</template>

<script>
import store from '@/store'
export default {
  name: 'blog-home',
  data () {
    return {
      page: 1,
      searchInput: '',
      categoriesQueryString: '',
      handleSearchIconOrCircular: false,
      categoriesValue: [],
      page_title: 'Blog',
      posts: []
    }
  },
  beforeRouteEnter (routeTo, RouteFrom, next) {
    store.dispatch('user/getCategoriesListForUser')
      .then(() => {
        next(vm => {
          vm.categoriesValue = RouteFrom.params.categories ? vm.getSelectedCategoriesList : []
          vm.page = RouteFrom.params.page ? RouteFrom.params.page : 1
          vm.searchInput = RouteFrom.params.title ? RouteFrom.params.title : ''
          vm.categoriesQueryString = RouteFrom.params.categories ? RouteFrom.params.categories : ''
          vm.debounceSearchInputByTitleOrCategories()
        })
      })
  },
  metaInfo () {
    return {
      title: 'Статті з психології від психотерапевтів на Платформі',
      meta: [
        {
          vmid: 'og:description',
          property: 'og:description',
          name: 'description',
          content: 'Найкращі психологічні статті допоможуть знайти відповіді на \n' +
            'непрості та вкрай важливі питання, відпочити душею та \n' +
            'познайомитися з думкою експертів проекту Платформа'
        },
        {
          name: 'keywords',
          content: 'Статті з психології, поради психолога, питання психологу, \n' +
            'блог з психології, у психолога'
        }
      ]
    }
  },
  watch: {
    categoriesValue () {
      store.commit('user/SET_SELECTED_CATEGORIES', this.categoriesValue)
      this.createCategoriesQueryString(this.categoriesValue)
      this.debounceSearchInputByTitleOrCategories()
    },
    page () {
      this.debounceSearchInputByTitleOrCategories()
    }
  },
  methods: {
    createCategoriesQueryString (data) {
      if (Array.isArray(data) && data.length > 0) {
        this.categoriesQueryString = data
          .map((value) => `categories[]=${value}`)
          .join('&')
      } else {
        this.categoriesQueryString = ''
      }
    },
    debounceSearchInputByTitleOrCategories () {
      if (this.categoriesQueryString) {
        this.page =
          this.page >= 1 && this.categoriesQueryString ? this.page : 1
      }

      this.handleSearchIconOrCircular = true
      store
        .dispatch('user/searchBlogByTitle', {
          title: this.searchInput,
          page: this.page,
          categories: this.categoriesQueryString
        })
        .finally(() => {
          this.page = this.blogArticles.meta.last_page < this.page ? 1 : this.page
          this.handleSearchIconOrCircular = false
        })
    },
    postLangCheck (content) {
      for (const i in content) {
        if (!content[i]) return true
      }
      return false
    },
    currentLocal (text) {
      if (this.localisation === 'ru' && text.ru) return text.ru
      else if (this.localisation === 'uk' && text.uk) return text.uk
      else return text.ru ? text.ru : text.uk
    }
  },
  computed: {
    getSelectedCategoriesList () {
      return store.getters['user/getSelectedCategoriesList']
    },
    categoriesItems () {
      return this.$store.getters['user/getCategoriesList'].data
    },
    localisation () {
      return this.$store.getters['app/getSelectedLocale']
    },
    blogArticles () {
      return this.$store.getters['user/getPublishedBlogsList']
    }
  }
}
</script>

<style lang="scss" scoped>
.devider {
  display: block;
  height: 2px;
  background-color: $primary;
}
.blog {
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  &__header {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    &__body_left {
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & p {
        max-width: 515px;
      }
    }
    &__body_right {
      max-width: 440px;
      & img {
        width: 100%;
      }
    }
  }

  &__action {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    & > span {
      font-size: 30px;
      font-weight: 500;
      color: $primary;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 60px;
    gap: 2rem;
    &__item {
      display: flex;
      flex-direction: column;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      min-height: 500px;
      width: 360px;
      &__btn {
        width: 80%;
        margin: auto auto 20px;
      }
      &__body {
        padding: 10px;
        word-wrap: break-word;
        &_title {
          text-decoration: none;
          font-size: 26px;
          color: $primary;
          &:hover {
            text-decoration: underline;
            text-decoration-thickness: 1px;
          }
        }

        &_subtitle {
          text-decoration: none;
          font-size: 20px;
          color: $primaryGray;
        }
      }
      & img {
        height: 180px;
        max-width: 360px;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
  }
}

.blog__search_wrapper {
  position: relative;
  width: 500px;
}
.blog__search_icon {
  color: #bcbcbc;
  position: absolute !important;
  top: 10px;
  right: 15px;
}
.blog__search_input {
  line-height: 40px !important;
  width: 100%;
  padding: 0 35px 0 10px;
  border-radius: 25px;
  border: 2px solid $primary;
  background-color: rgb(214, 226, 234);
  outline: none;
  transition: width 0.8s;
}

.blog__categories_wrapper {
  width: 350px;
}

.blog__categories_item {
  width: 100%;
  border: none;
  &:hover {
    outline: 1px solid $primary !important;
    border: none !important;
  }
}

.primary_rowLine {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom: 2px solid $primary;
}

.body_title {
  line-height: 1.2em;
  width: 750px;
  text-align: center;
  color: $primary;
  font-weight: 500;
  font-size: 30px;
}

.body_content {
  line-height: 1.2em;
  margin-top: 40px;
  text-align: center;
  color: $primaryGray;
  font-weight: 400;
  font-size: 26px;
  width: 620px;
}
.body__additionally {
  display: flex;
  position: relative;
  margin-bottom: 30px;
  &_text {
    font-family: Caveat, serif;
    text-align: center;
    color: $primary;
    font-weight: 400;
    font-size: 30px;
  }
  &_arrow {
    display: block;
    position: relative;
    top: 22px;
    left: 15px;
    margin-right: -114px;
  }
}
@media screen and (max-width: 868px) {
  .blog__header {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    &__body_left {
      align-items: center;
    }
  }
  .body__additionally_arrow {
    display: none;
  }
}
</style>
